import './App.css';
import React, { useState, useEffect } from 'react';
import * as firebase from 'firebase';

import {
  createMuiTheme,
  ThemeProvider,
  makeStyles,
} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import CircularProgress from '@material-ui/core/CircularProgress';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import Button from '@material-ui/core/Button';
import Banner from './components/Banner';
import JobItem from './components/JobItem';

function Copyright() {
  return (
    <Typography
      variant="body2"
      color="textSecondary"
      align="center"
      className={useStyles().copyright}
    >
      {'Copyright © '}
      <Link
        color="inherit"
        href="https://ekkiden.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Ekkiden
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createMuiTheme({
  typography: {
    fontFamily: `"Poppins", "Helvetica", "Arial", sans-serif`,
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
  palette: {
    primary: {
      light: '#FFF',
      main: '#FFF',
      dark: '#cccccc',
      contrastText: '#000000',
    },
    secondary: {
      light: '#636b4a',
      main: '#636b4a',
      dark: '#636b4a',
      contrastText: '#fff',
    },
    textSecondary: '#000000',
    errors: {
      light: '#d2533a',
      main: '#d2533a',
      dark: '#d2533a',
      contrastText: '#fff',
    },
    background: {
      paper: '#fff',
      default: '#F6FAFF',
    },
  },
});

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  logo: {
    maxHeight: 42,
  },

  nbResult: {
    marginLeft: theme.spacing(1),
    width: theme.spacing(120),
  },
  layout: {
    width: 'auto',
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
  },

  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  appBarButton: {
    magin: theme.spacing(2),
  },
  copyright: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  titleBlue: {
    fontSize: 42,
    fontWeight: 700,
    fontFamily: 'rocaone',
  },
}));

export default function App() {
  const db = firebase.firestore();

  const classes = useStyles();
  //HOOKS
  const [jobList, setJobList] = useState([]); //Job list to show eventually filtered
  const [nbJobsToShow, setNBJobsToShow] = useState(20);

  const [fullJobList, setFullJobList] = useState([]); // The whole jobList without filtering
  const [isLoading, setIsLoading] = useState(true);

  // This effect runs one time for each session
  useEffect(() => {
    setIsLoading(true);
    async function getJobList() {
      const jobsRef = db.collection('jobs');
      const snapshot = await jobsRef.get();

      let joblist = [];
      snapshot.forEach((doc) => {
        let job = doc.data();
        job.id = doc.id;
        joblist.push(job);
      });

      console.log(joblist);
      setJobList(joblist);
      setFullJobList(joblist);
      setIsLoading(false);
    }
    getJobList();
  }, [db]);

  return (
    <ThemeProvider theme={theme}>
      <React.Fragment>
        <CssBaseline />
        <AppBar position="absolute" color="primary" className={classes.appBar}>
          <Toolbar>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Grid
                container
                item
                xs
                direction="row"
                alignItems="center"
                justify="flex-start"
              >
                <a
                  href="https://www.ekkiden.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={require('./img/ekkiden-logo.png')}
                    alt="Ekkiden"
                    className={classes.logo}
                  />
                </a>
              </Grid>
              <Grid item xs align="center">
                <a
                  href="https://www.ekkiden.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={require('./img/ekkiden-dark-blue.png')}
                    alt="Ekkiden"
                    className={classes.logo}
                  />
                </a>
              </Grid>
              <Grid item xs align="right">
                <Button
                  className={classes.appBarButton}
                  variant="contained"
                  color="secondary"
                  onClick={() =>
                    window.open('https://recruiterflow.com/ekkiden/jobs/13')
                  }
                >
                  Spontaneous Application
                </Button>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="stretch"
          className={classes.layout}
          spacing={2}
        >
          <Grid item xs={12} justify="center" container alignItems="center">
            <Banner
              fullJobList={fullJobList}
              jobList={jobList}
              setJobList={setJobList}
              setIsLoading={setIsLoading}
            />
          </Grid>
          <Grid item xs={12} justify="center" container alignItems="center">
            <Typography className={classes.titleBlue}>
              It's time to join the adventure
            </Typography>
          </Grid>
          <Grid item xs={12} justify="center" container alignItems="center">
            <Typography>
              And to unleash your potential{' '}
              <span role="img" aria-label="Spaceship emoji">
                {' '}
                🚀
              </span>
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            justify="center"
            container
            align="right"
            spacing={4}
          >
            <Grid item xs={6} align="right">
              <Link
                color="secondary"
                href="https://www.ekkiden.com/about"
                target="_blank"
                rel="noopener noreferrer"
              >
                See our values
              </Link>
            </Grid>
            <Grid item xs={6} align="left">
              <Link
                color="secondary"
                href="https://www.ekkiden.com/career"
                target="_blank"
                rel="noopener noreferrer"
              >
                Discover our teams
              </Link>
            </Grid>
          </Grid>
          {jobList === undefined || isLoading ? null : (
            <Grid item xs={12} justify="center" container alignItems="center">
              <Typography variant="h5" className={classes.nbResult}>
                {jobList.length + ' jobs for your search'}
              </Typography>
            </Grid>
          )}

          {jobList === undefined || isLoading ? (
            <Grid item xs={12} container justify="center">
              <CircularProgress color="secondary" />
            </Grid>
          ) : (
            jobList
              .reverse()
              .slice(0, nbJobsToShow)
              .map(
                (
                  job,
                  index, //Reverse to change the order and put the last published on top
                ) => (
                  <Grid item xs={12} key={job.id} justify="center" container>
                    <JobItem jobOffer={job} />
                  </Grid>
                ),
              )
          )}
          {nbJobsToShow < jobList.length ? (
            <Grid item xs={12} container justify="center">
              <Button
                color="secondary"
                onClick={() => {
                  const NBjobsToLoad = 20;
                  if (nbJobsToShow + NBjobsToLoad < jobList.length)
                    setNBJobsToShow(nbJobsToShow + 20);
                  else setNBJobsToShow(jobList.length);
                }}
              >
                Load more
              </Button>
            </Grid>
          ) : null}
        </Grid>
        <Copyright />
      </React.Fragment>
    </ThemeProvider>
  );
}
