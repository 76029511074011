import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import App from './App';
import * as serviceWorker from './serviceWorker';
import firebase from "firebase";
import 'firebase/analytics';

const firebaseConfig = {
  apiKey: "AIzaSyArTez37LBqNe8m4dcKjFe5z5rBFrmbrMQ",
  authDomain: "ekkiden-jobs.firebaseapp.com",
  databaseURL: "https://ekkiden-jobs.firebaseio.com",
  projectId: "ekkiden-jobs",
  storageBucket: "ekkiden-jobs.appspot.com",
  messagingSenderId: "232315374908",
  appId: "1:232315374908:web:3e50a73639c155df9ea239",
  measurementId: "G-GHPC1HPZLC"
};
firebase.initializeApp(firebaseConfig);
firebase.analytics();


ReactDOM.render(
  <App />,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
